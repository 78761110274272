import React from "react";
import Octicon from 'react-octicon'
import { useDrag } from 'react-dnd'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

function User({user_id, 
    showVacation = true, 
    showBusy = false, 
    inline = false, 
    leader = false, 
    reviewer = false, 
    roleCaption = null,
    onClick = function() {},
    onRemove = null}) {
    
    function getVacationIcon() {
        let icon = "es-beach"
        if (user.on_vacation_type == "event") {
            icon = "es-bell"
        } else if (user.on_vacation_type == "travel") {
            icon = "es-plane"
        }
        return (<>
            {showVacation && user.on_vacation === true &&
                <span className='badge badge-secondary'>
                    <span className={`es ${icon}`}></span>
                </span>
            }
            {showVacation && user.on_vacation_soon === true &&
                <span className='badge badge-primary'>
                    <span className={`es ${icon}`}></span>
                    <sup>{user.oooDays}</sup>
                    &nbsp;{user.on_vacation_soon_message}
                </span>
            }
        </>)
    }

    const users = useSelector(state => state.data.users)
    let user = null
    if (user_id) {
        user = users.find(u => u.id === user_id)
    } 
    if (! user) {
        user = {user_id: null, role: null}
    }

    const [{isDragging}, drag] = useDrag({
        item: { type: 'user', user_id: user_id, role: user.role},
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    })

    if (user_id && user) {
        if (inline) {
            return (
                <span ref={drag} className="user-component" onClick={() => onClick(user)}>
                    <Octicon mega name="person"/>
                    <span className="user-component-caption">{user.caption}</span>
                </span>
            )
        } else {
            return (
                <div ref={drag} className={classNames("user-component", {"user-component-with-delete": onRemove, "user-component-no-delete": (! onRemove)})} onClick={() => onClick(user)}>
                    {onRemove && 
                        <span className="text-secondary user-component-delete"><Octicon onClick={onRemove} name="x" mega/></span>
                    }

                    <span className={user.on_vacation ? 'user-component-icon-wrapper user-on-vacation' : 'user-component-icon-wrapper'}>
                        <Octicon mega className="person-icon" name="person"/>
                        {showBusy && user.deals_cnt == 2 &&
                            <span className="badge badge-warning user-component-busy-icon">{user.deals_cnt}</span>
                        }
                        {showBusy && user.deals_cnt >= 3 &&
                            <span className="badge badge-danger user-component-busy-icon">{user.deals_cnt}</span>
                        }
                        {leader &&
                            <span className="user-component-type-icon-topleft">
                                <Octicon name="star"/>
                            </span>
                        }
                        {reviewer &&
                            <span className="user-component-type-icon-topright">
                                <Octicon name="verified"/>
                            </span>
                        }

                    </span>
                    <span className="user-component-caption">
                    {user.caption} &nbsp;
                    {getVacationIcon()}
                    {roleCaption && roleCaption.map(caption => (
                        <span>
                        <span class="badge badge-secondary">{caption}</span>&nbsp;
                        </span>
                    ))}
                    </span>
                </div>
            )
        }
    } else {
        return (
            <div className="user-component user-component-empty">
                None
            </div>
        )
    }
}

export default User;